import React, { useContext, useEffect, useRef } from 'react';
import './infographic.scss';
import { ReactComponent as Heart } from '../../assets/heart.svg';
import { ReactComponent as Globe } from '../../assets/globe.svg';
import { ReactComponent as Work } from '../../assets/work.svg';
import { ReactComponent as Active } from '../../assets/active.svg';
import { gsap } from '../../gsap';
import { WidthContext } from '../../WidthContext';

function Infographic() {
  const container = useRef();
  const { width } = useContext(WidthContext);
  const globeRef = useRef();
  const activeRef = useRef();
  const workRef = useRef();
  const heartTextRef = useRef();
  const globeHeadRef = useRef();
  const globeTextRef = useRef();
  const workHeadRef = useRef();
  const workText1Ref = useRef();
  const workText2Ref = useRef();
  const activeTextRef = useRef();

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (width > 767) {
      }
      const mainTl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 60%'
        }
      });
      const row2Tl = gsap.timeline({
        scrollTrigger: {
          trigger: workRef.current,
          start: 'top 60%'
        }
      });
      const globeOptions = {
        scrollTrigger: {
          trigger: globeRef.current,
          start: 'top 60%'
        }
      };

      const activeOptions = {
        scrollTrigger: {
          trigger: activeRef.current,
          start: 'top 60%'
        }
      };

      const globeTl = gsap.timeline(width < 768 ? globeOptions : {});

      const activeTl = gsap.timeline(width < 768 ? activeOptions : {});

      gsap.set('#heart', { transformOrigin: 'center', scale: 0.1 });
      gsap.set('#dollar', { transformOrigin: 'center', scale: 0.1 });
      gsap.set('#cloud-right', { transformOrigin: 'center', scale: 0.1 });
      gsap.set('#cloud-left', { transformOrigin: 'center', scale: 0.1 });
      mainTl
        .to('#heart', {
          opacity: 1,
          scale: 1.1,
          duration: 0.7
        })
        .to('#heart', {
          scale: 1,
          duration: 0.5
        })
        .to(
          '#dot-left',
          {
            opacity: 1,
            duration: 0.5
          },
          '<'
        )
        .to(
          '#rate',
          {
            opacity: 1,
            duration: 2,
            ease: 'linear',
            strokeDashoffset: 0
          },
          '<'
        )
        .to(
          '#dot-right',
          {
            opacity: 1,
            duration: 0.5
          },
          '<.5'
        )
        .to(
          heartTextRef.current,
          {
            opacity: 1,
            duration: 0.5,
            y: 0
          },
          '<'
        );
      globeTl
        .to(globeHeadRef.current, { opacity: 1, y: 0, duration: 0.5 })
        .to(
          '#globe-outline',
          { opacity: 1, duration: 1.5, ease: 'linear', strokeDashoffset: 0 },
          '<'
        )
        .to('#globe-contents', { opacity: 1, duration: 1, ease: 'linear' }, '<1')
        .to('#flight-path', { opacity: 1, duration: 1.5, ease: 'linear', strokeDashoffset: 0 }, '<')
        .to('#plane', { opacity: 1, duration: 0.5 }, '<0.5')
        .to(globeTextRef.current, { opacity: 1, y: 0, duration: 0.5 }, '<');
      row2Tl
        .to(workHeadRef.current, { opacity: 1, y: 0, duration: 0.5 })
        .to('#building', { opacity: 1, y: 0, duration: 0.5 }, '<')
        .to('#shrub', { opacity: 1, y: 0, duration: 0.5 })
        .to('#sign', { opacity: 1, y: 0, duration: 0.5 })
        .to('#dollar', { opacity: 1, scale: 1, rotate: 360, duration: 0.5 })
        .to(workText1Ref.current, { opacity: 1, y: 0, duration: 0.5 }, '<')
        .to(workText2Ref.current, { opacity: 1, y: 0, duration: 0.5 });
      activeTl
        .to('#person', { opacity: 1, x: 0, duration: 0.5 })
        .to('#mt1', { opacity: 1, y: 0, strokeDashoffset: 0, duration: 1 })
        .to('#mt5', { opacity: 1, strokeDashoffset: 0, duration: 1 }, '<')
        .to('#mt2', { opacity: 1, strokeDashoffset: 0, duration: 1 }, '<')
        .to('#mt3', { opacity: 1, strokeDashoffset: 0, duration: 1 }, '<')
        .to('#mt4', { opacity: 1, strokeDashoffset: 0, duration: 1 }, '<')
        .to('#cloud-left', { opacity: 1, scale: 1, duration: 0.5 }, '<0.5')
        .to('#cloud-right', { opacity: 1, scale: 1, duration: 0.5 })
        .to(activeTextRef.current, { opacity: 1, y: 0, duration: 0.5 }, '<');

      if (width > 767) {
        mainTl.add(globeTl, '<0.5');
        row2Tl.add(activeTl, '<0.5');
      }
    });

    return () => ctx.revert();
  }, [width]);
  return (
    <section className="infographic" ref={container}>
      <div className="infographic__row infographic__row--top">
        <div className="infographic__item infographic__item--left infographic__item-heart">
          <div className="infographic__item__icon infographic__item__icon-heart">
            <Heart />
          </div>
          <div className="infographic__item__text" ref={heartTextRef}>
            <h3>Seniors are healthier than ever</h3>
            <p>
              Today’s seniors are <span className="text--green">healthier</span> overall, and living
              independently for longer<sup>1</sup>, and the share of older Americans in poor health
              has dropped steadily since 1998
              <sup>2</sup>.
            </p>
            <cite>1 Pew Research Center Study</cite>
            <cite>2 Urban Institute; Health and Retirement Study</cite>
          </div>
        </div>
        <div
          className="infographic__item infographic__item--right infographic__item-globe"
          ref={globeRef}
        >
          <div className="infographic__item__text" ref={globeHeadRef}>
            <h3>Seniors are traveling more than ever before</h3>
          </div>
          <div className="infographic__item__bottom">
            <div className="infographic__item__icon infographic__item__icon-globe">
              <Globe />
            </div>
            <div className="infographic__item__text" ref={globeTextRef}>
              <span className="infographic__item__text__num text--green">
                46<span className="infographic__item__text__num--small">%</span>
              </span>
              <p className="infographic__item__text--nmt">
                of American travelers are seniors, making them the{' '}
                <span className="text--green">largest travel demographic</span> in 2023 and
                signaling a return to pre-COVID trends<sup>3</sup>.
              </p>
              <cite>3 Travel Agent Central; Squaremouth Analytics Study</cite>
            </div>
          </div>
        </div>
      </div>
      <div className="infographic__row">
        <div
          className="infographic__item infographic__item--left infographic__item-work"
          ref={workRef}
        >
          <div className="infographic__item__text" ref={workHeadRef}>
            <h3>Seniors are power players in the workforce</h3>
          </div>
          <div className="infographic__item__bottom">
            <div className="infographic__item__icon infographic__item__icon-work">
              <Work />
            </div>
            <div className="infographic__item__text" ref={workText1Ref}>
              <span className="infographic__item__text__num text--green">
                41<span className="infographic__item__text__num--small">%</span>
              </span>
              <p className="infographic__item__text--nmt">
                of small business owners in the US are Baby Boomers<sup>4</sup>
              </p>
            </div>
          </div>
          <div className="infographic__item__text" ref={workText2Ref}>
            <p>
              An unprecedented <span className="text--green">24%</span> of men and{' '}
              <span className="text--green">16%</span> of women in the workforce are over 65, and
              this trend is expected to continue<sup>5</sup>.
            </p>
            <cite>4 Zippia Small Business Statistics Study</cite>
            <cite>
              5 U.S. Census Bureau, Population Survey; U.S. Bureau of Labor Statistics, Employment
              Projections Program
            </cite>
          </div>
        </div>
        <div
          className="infographic__item infographic__item--right infographic__item-active"
          ref={activeRef}
        >
          <div className="infographic__item__icon infographic__item__icon-active">
            <Active />
          </div>
          <div className="infographic__item__text" ref={activeTextRef}>
            <h3>Seniors see the greatest benefits from staying active</h3>

            <p>
              People age 65+ enjoy the most significant benefits from exercise, both the{' '}
              <span className="text--green">biggest increase in life optimism</span> and the
              greatest decrease in life stress vs. other age groups<sup>6</sup>.
            </p>
            <cite>6 Gallup-Sharecare Well-Being Index</cite>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Infographic;
