import React, { useContext, useState, useEffect, useRef } from 'react';
import './nav.scss';
import Logo from '../logo/Logo';
import { navItems } from '../../navItems';
import { WidthContext } from '../../WidthContext';
import { gsap, ScrollTrigger } from '../../gsap';
import FilmIcon from '../filmIcon/FilmIcon';

function Nav({ positionMobileMenu, navRef, hubRef }) {
  const [expand, setExpand] = useState(true);
  const [bg, setBg] = useState(false);
  const { width } = useContext(WidthContext);
  const menuRef = useRef();
  const xRef = useRef();
  const menuListRef = useRef();

  const handleExpand = (state, atTop) => {
    const collapseTl = gsap.timeline();
    const expandTl = gsap.timeline();
    if (atTop) {
      xRef.current.style.display = 'none';
    } else {
      xRef.current.style.display = 'inline-block';
    }
    if (state === 'close') {
      collapseTl
        .to(xRef.current, {
          opacity: 0,
          scale: 0.5,
          duration: 0.5
        })
        .to(
          menuListRef.current,
          {
            x: '30px',
            opacity: 0,
            duration: 0.7
          },
          '<'
        )
        .to(
          menuRef.current,
          {
            scale: 1,
            opacity: 1,
            duration: 0.5,
            visibility: 'visible'
          },
          '<0.3'
        )
        .to(
          menuListRef.current,
          {
            visibility: 'hidden'
          },
          '<'
        )
        .to(
          xRef.current,
          {
            visibility: 'hidden'
          },
          '<'
        );

      setExpand(false);
    } else {
      expandTl
        .to(menuListRef.current, {
          x: 0,
          opacity: 1,
          duration: 0.7,
          visibility: 'visible',
          ease: 'back.out(2)'
        })
        .to(menuRef.current, { scale: 0.5, opacity: 0, duration: 0.5 }, '<.2')
        .to(
          xRef.current,
          {
            scale: 1,
            opacity: 1,
            duration: 0.5,
            visibility: 'visible',
            zIndex: 11
          },
          '<.3'
        )
        .to(menuRef.current, { visibility: 'hidden' });

      setExpand(true);
    }
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: hubRef.current,
        start: '0.5% top',
        onEnter: () => {
          setBg(true);
          if (width > 767) {
            handleExpand('close', true);
          }
        },
        onLeaveBack: () => {
          setBg(false);
          if (width > 767) {
            handleExpand('open', true);
          }
        }
      });
    });
    return () => ctx.revert();
  }, [hubRef, navRef, width]);

  useEffect(() => {
    if (width < 768) {
      gsap.to(menuRef.current, { visibility: 'visible', scale: 1, opacity: 1, duration: 0.5 });
      setExpand(false);
    }
    if (width > 767 && !bg) {
      gsap.to(menuRef.current, { visibility: 'hidden', scale: 0.5, opacity: 0, duration: 0.5 });
    }
  }, [width, expand, bg]);

  return (
    <nav className={bg ? 'nav nav--bg' : 'nav'} ref={navRef} aria-label="section navigation">
      <a
        href="https://www.humana.com/"
        target="_blank"
        rel="noreferrer"
        aria-label="Visit www.humana.com"
        className={bg ? 'nav__logo nav__logo--bg' : 'nav__logo'}
      >
        <Logo origin="nav" />
      </a>
      {width > 767 && (
        <div className="nav__links">
          <ul className="nav__links__list" ref={menuListRef}>
            {navItems.map(({ href, copy, copy2 }, i) => {
              return (
                <li className="nav__item" key={i}>
                  {copy2 ? (
                    <>
                      <span className="nav__item__link nav__item__link-cs">
                        <FilmIcon origin={'nav'} />
                        {copy}
                      </span>
                      <span className="nav__item__link--cs">Coming Soon</span>
                    </>
                  ) : (
                    <a
                      className={bg ? 'nav__item__link nav__item__link--bg' : 'nav__item__link'}
                      href={href}
                    >
                      <FilmIcon origin={'nav'} />
                      {copy}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
          <button
            className={bg ? 'nav__close nav__close--bg' : 'nav__close'}
            aria-label="Close menu"
            onClick={() => {
              handleExpand('close');
            }}
            ref={xRef}
          >
            <svg
              className="nav__close__icon"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                className="nav__close__stroke"
                x1="3.39296"
                y1="2.85791"
                x2="28.1417"
                y2="27.6066"
              />
              <line className="nav__close__stroke" x1="3" y1="27.7486" x2="27.7487" y2="2.99991" />
            </svg>
          </button>
        </div>
      )}
      <button
        className={bg ? 'nav__menu nav__menu--bg' : 'nav__menu'}
        aria-controls="menu"
        aria-label="Open menu"
        onClick={
          width > 767
            ? () => {
                handleExpand('open');
              }
            : positionMobileMenu
        }
        ref={menuRef}
      >
        <svg
          viewBox="0 0 22 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="nav__menu__icon"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9 6.67071H1.1C0.495 6.67071 0 6.29548 0 5.83687C0 5.37826 0.495 5.00303 1.1 5.00303H20.9C21.505 5.00303 22 5.37826 22 5.83687C22 6.29548 21.505 6.67071 20.9 6.67071ZM20.9 1.66768H1.1C0.495 1.66768 0 1.29245 0 0.833839C0 0.375227 0.495 0 1.1 0H20.9C21.505 0 22 0.375227 22 0.833839C22 1.29245 21.505 1.66768 20.9 1.66768ZM1.1 10.0061H20.9C21.505 10.0061 22 10.3813 22 10.8399C22 11.2985 21.505 11.6737 20.9 11.6737H1.1C0.495 11.6737 0 11.2985 0 10.8399C0 10.3813 0.495 10.0061 1.1 10.0061Z"
          />
        </svg>
      </button>
    </nav>
  );
}

export default Nav;
