// Updated to serve static data after the campaign ended.

// import React, { useEffect, useRef, useState, useCallback } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import './poll.scss';
import { pollData } from './pollData';
import Result from './Result';
import { gsap } from '../../gsap';
// import axios from 'axios';

function Poll({ index, container, imageRef }) {
  const { a, head, q, campaign } = pollData[index];
  const [results, setResults] = useState([]);
  const [showCounter, setShowCounter] = useState(false);
  const p1C1Ref = useRef();
  const p1C2Ref = useRef();
  const p1C3Ref = useRef();
  const p2C1Ref = useRef();
  const p2C2Ref = useRef();
  const p2C3Ref = useRef();
  const p3C1Ref = useRef();
  const p3C2Ref = useRef();
  const p3C3Ref = useRef();
  const resultRefs = [
    [p1C1Ref, p1C2Ref, p1C3Ref],
    [p2C1Ref, p2C2Ref, p2C3Ref],
    [p3C1Ref, p3C2Ref, p3C3Ref]
  ];
  const currentResultRef = resultRefs[index];

  // const fetchData = useCallback(() => {
  //   var config = {
  //     method: 'get',
  //     url: `https://92c9a27dc3.execute-api.us-east-1.amazonaws.com/production/campaign/${campaign}`
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       const currentQ = response.data.Items[0];
  //       const currentChoice1 = +currentQ['A1']['N'];
  //       const currentChoice2 = +currentQ['B1']['N'];
  //       const currentChoice3 = +currentQ['C1']['N'];
  //       let total = currentChoice1 + currentChoice2 + currentChoice3;
  //       let c1P = Math.round((currentChoice1 / total) * 100);
  //       let c2P = Math.round((currentChoice2 / total) * 100);
  //       let c3P = Math.round((currentChoice3 / total) * 100);
  //       let temp = [c1P, c2P, c3P];
  //       setResults(temp);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [campaign]);

  useEffect(() => {
    const staticResult = {
      poll1: [154, 129, 69],
      poll2: [133, 81, 67],
      poll3: [132, 77, 50]
    };
    const currentChoice1 = (staticResult[campaign][0] += 1);
    const currentChoice2 = (staticResult[campaign][1] += 1);
    const currentChoice3 = (staticResult[campaign][2] += 1);
    let total = currentChoice1 + currentChoice2 + currentChoice3;
    let c1P = Math.round((currentChoice1 / total) * 100);
    let c2P = Math.round((currentChoice2 / total) * 100);
    let c3P = Math.round((currentChoice3 / total) * 100);
    let temp = [c1P, c2P, c3P];
    setResults(temp);
  }, [campaign]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const graphTl = gsap.timeline();
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 60%',
          once: true,
          onEnter: () => {
            setShowCounter(true);
          }
        }
      });
      tl.to(container.current, {
        opacity: 1,
        y: 0,
        duration: 0.5
      });
      currentResultRef.forEach((ref, i) => {
        graphTl.to(
          ref.current,
          {
            width: `${results[i]}%`,
            duration: 1.2
          },
          '<'
        );
      });
      tl.add(graphTl);
    });
    return () => ctx.revert();
  }, [container, currentResultRef, results]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(imageRef.current, { backgroundSize: '115% auto' });
      const imageTl = gsap.timeline({
        scrollTrigger: {
          trigger: imageRef.current,
          start: 'top 70%'
        }
      });
      imageTl.to(imageRef.current, { backgroundSize: '105% auto', duration: 0.5 });
    });
    return () => ctx.revert();
  }, [imageRef]);

  return (
    <div className={`poll poll-${index}`}>
      <div className="poll__result">
        <div className="poll__result-contents text--animate" ref={container}>
          <h2>{head}</h2>
          <span className="poll__result__small">Poll Results</span>
          <h3>{q}</h3>
          {a.map((answer, i) => {
            return (
              <Result
                result={results[i]}
                answer={answer}
                i={i}
                index={index}
                key={answer}
                resultRef={resultRefs[index][i]}
                showCounter={showCounter}
              />
            );
          })}
        </div>
      </div>
      <div
        ref={imageRef}
        className={
          index % 2 === 0
            ? `poll__photo poll__photo-${index} poll__photo-even`
            : `poll__photo poll__photo-${index} poll__photo-odd`
        }
      />
    </div>
  );
}

export default Poll;
