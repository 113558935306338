export const pollData = [
  {
    head: 'There’s a lot more to see out there',
    q: 'What is the next chapter of your life all about?',
    a: ['Travel and adventure', 'Time with loved ones', 'Volunteering and giving back'],
    // campaign: 'Humana poll 1 f07638f5',
    campaign: 'poll1',
    params: {
      ' TableName': 'pollTest',
      Key: {
        campaign: {
          S: 'Humana poll 1 f07638f5'
        }
      }
    }
  },
  {
    head: 'There’s power in following your passions',
    q: 'How do you feel when you think about retirement?',
    a: ['Sense of excitement', 'Intimidated by the free time', 'Curious about new hobbies'],
    // campaign: 'Humana poll 2 835a2d67',
    campaign: 'poll2',
    params: {
      ' TableName': 'pollTest',
      Key: {
        campaign: {
          S: 'Humana poll 2 835a2d67'
        }
      }
    }
  },
  {
    head: 'The foundation for all happiness is good health',
    q: 'What do you think you’ll need the most help with in retirement?',
    a: ['Navigating healthcare', 'Keeping up with technology', 'High costs for wellness'],
    // campaign: 'Humana poll 3 e00b93c5',
    campaign: 'poll3',
    params: {
      ' TableName': 'pollTest',
      Key: {
        campaign: {
          S: 'Humana poll 3 e00b93c5'
        }
      }
    }
  }
];
