import { useEffect, useRef, useContext, useState } from 'react';
import Player from '@vimeo/player';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';
import { filmData } from './filmData';
import './film.scss';
import Button from '../button/Button';
import FilmIcon from '../filmIcon/FilmIcon';
import { WidthContext } from '../../WidthContext';
import { gsap } from '../../gsap';

const Film = ({ index, filmRef, filmModalRef, setShowCopied, navRef, filmContRef }) => {
  const closeRef = useRef();
  const animateRef = useRef();
  const { width, touchDevice } = useContext(WidthContext);
  const { copy, name, id, videoId, loopD, loopM, url } = filmData[index];
  const [currentFilm, setCurrentFilm] = useState(null);

  const sendClickEventsShare = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'click_share', event_name: id });
    }
  };

  useEffect(() => {
    filmRef.current = new Player(videoId, {
      url: url,
      controls: true,
      frameborder: '0',
      responsive: true,
      playsinline: false
    });
  }, [videoId, filmRef, url]);

  useEffect(() => {
    filmRef.current.on('ended', () => {
      setCurrentFilm(null);
    });
  }, [setCurrentFilm, filmRef]);

  useEffect(() => {
    filmRef.current.on('play', () => {
      navRef.current.style.transform = 'translateY(-200px)';
      if (width < 768 && !touchDevice) {
        const offsetTop = filmContRef.current.getBoundingClientRect().height / 2 - width * 0.5625;
        gsap.to(window, { scrollTo: { y: filmContRef.current, offsetY: -offsetTop } });
      }
    });
    filmRef.current.on('pause', () => {
      navRef.current.style.transform = 'translateY(0px)';
    });
  }, [navRef, filmRef, width, filmContRef, touchDevice]);

  useEffect(() => {
    if (currentFilm === null) {
      navRef.current.style.transform = 'translateY(0)';
    }
  }, [currentFilm, navRef]);

  useEffect(() => {
    if (touchDevice) {
      filmRef.current.on('fullscreenchange', function (data) {
        if (!data.fullscreen) {
          setCurrentFilm(null);
        }
      });
    }
  }, [setCurrentFilm, filmRef, touchDevice]);

  useEffect(() => {
    const container = animateRef.current;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            animateRef.current.style.opacity = 1;
            animateRef.current.style.transform = 'translateY(0)';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(container);
    return () => {
      observer.unobserve(container);
    };
  }, []);

  useEffect(() => {
    if (currentFilm === index) {
      filmModalRef.current.style.visibility = 'visible';
      closeRef.current.style.visibility = 'visible';
      filmRef.current.play();
    } else {
      filmModalRef.current.style.visibility = 'hidden';
      closeRef.current.style.visibility = 'hidden';
      filmRef.current.pause();
    }
  }, [currentFilm, filmModalRef, filmRef, index]);

  return (
    <section className="film" id={id} ref={filmContRef}>
      <video muted autoPlay loop playsInline className="videoloop videoloop-d">
        <source src={loopD} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <video muted autoPlay loop playsInline className="videoloop videoloop-m">
        <source src={loopM} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <div className="film-wrapper">
        <div className="film-content text--animate text--animate-transition" ref={animateRef}>
          <div className="film-content__head">
            <FilmIcon origin={'film'} />
            <h2>{name}</h2>
          </div>

          <p>{copy}</p>

          <div className="film__buttons">
            <Button origin={'film'} click={setCurrentFilm} index={index} id={id} />
            <button
              className="film__buttons-share"
              onClick={() => {
                sendClickEventsShare();
                navigator.clipboard.writeText(`${window.location.hostname}/#${id}`).then(
                  function () {
                    setShowCopied(true);
                  },
                  function () {
                    console.log('Copy error');
                  }
                );
              }}
            >
              share <ShareIcon />
            </button>
          </div>
        </div>
      </div>
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        className="film__close"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Exit video player"
        onClick={() => {
          setCurrentFilm(null);
        }}
        ref={closeRef}
      >
        <circle cx="21.9199" cy="21.2583" r="20.5" stroke="white" className="film__close--stroke" />
        <path
          d="M15.9199 16.3755L17.0371 15.2583L21.9056 20.1268L26.8027 15.2583L27.9199 16.3755L23.0514 21.244L27.9199 26.1411L26.8027 27.2583L21.9056 22.3898L17.0371 27.2583L15.9199 26.1411L20.7884 21.244L15.9199 16.3755Z"
          fill="white"
          className="film__close--fill"
        />
      </svg>
      <div className="film__filmModal" ref={filmModalRef}>
        <div ref={filmRef} id={videoId} className="film-video" />
      </div>
    </section>
  );
};

export default Film;
