import React, { useEffect, useRef } from 'react';
import './cta.scss';
import hDots from '../../assets/h_dots.svg';
import Logo from '../logo/Logo';
import Button from '../button/Button';

function Cta() {
  const animateRef = useRef();
  useEffect(() => {
    const container = animateRef.current;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            animateRef.current.style.opacity = 1;
            animateRef.current.style.transform = 'translateY(0)';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(container);
    return () => {
      observer.unobserve(container);
    };
  }, []);
  return (
    <div className="cta">
      <img src={hDots} alt="" className="cta__icon" />
      <div className="cta-contents text--animate text--animate-transition" ref={animateRef}>
        <Logo origin="cta" />

        <p>Understand how Humana supports you through your personal health journey</p>
        <Button origin="cta" />
      </div>
      <span className="cta__din">GHHM3YNEN</span>
    </div>
  );
}

export default Cta;
