import React from 'react';
import './filmIcon.scss';

function FilmIcon({ origin }) {
  return (
    <svg
      version="1.1"
      className={`filmIcon filmIcon-${origin}`}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 20.7"
    >
      <path
        className={`filmIcon-${origin}__path`}
        d="M24,3.1C24,1.4,22.5,0,20.8,0H0.5C0.2,0,0,0.2,0,0.5v4.4v12l0,0.5c0,1.7,1.4,3.1,3.1,3.1h20.4
c0.3,0,0.5-0.2,0.5-0.5V3.6L24,3.1z M20.8,0.9c1.2,0,2.3,1,2.3,2.2v1.4H0.9l0-3.6H20.8z M3.1,19.6c-1.2,0-2.3-1-2.3-2.2l0-12h22.2
l0,14.2H3.1z M2.7,2.7c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S4,3.6,3.5,3.6S2.7,3.2,2.7,2.7z M5.3,2.7c0-0.5,0.4-0.9,0.9-0.9
s0.9,0.4,0.9,0.9S6.7,3.6,6.2,3.6C5.7,3.6,5.3,3.2,5.3,2.7z M8,2.7c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S9.4,3.6,8.9,3.6
C8.4,3.6,8,3.2,8,2.7z M14.8,11.8L10.9,9c-0.2-0.2-0.5-0.2-0.7-0.1C9.9,9.1,9.8,9.3,9.8,9.6v5.6c0,0.3,0.2,0.5,0.4,0.7
c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1l3.9-2.8c0.2-0.1,0.3-0.4,0.3-0.6C15.1,12.2,15,12,14.8,11.8z M10.7,14.9v-5l3.5,2.5
L10.7,14.9z"
      />
    </svg>
  );
}

export default FilmIcon;
