export const navItems = [
  {
    href: '#robin',
    film: 'Film 1',
    copy: 'Meet Robin',
    copy2: false
  },
  {
    href: '#gid',
    film: 'Film 2',
    copy: 'Meet Gid',
    copy2: false
  },
  {
    href: '#norma',
    film: 'Film 3',
    copy: 'Meet Norma',
    copy2: false
  }
];
