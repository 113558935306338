import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import './styles/app.scss';
import Quote from './components/quote/Quote';
import Cta from './components/cta/Cta';
import Nav from './components/nav/Nav';
import { gsap } from './gsap';
import { WidthContextProvider } from './WidthContext';
import MobileMenu from './components/mobileMenu/MobileMenu';
import Film from './components/film/Film';
import Button from './components/button/Button';
import Poll from './components/poll/Poll';
import Hero from './components/hero/Hero';
import HeroCopy from './components/hero/HeroCopy';
import Infographic from './components/infographic/Infographic';

function App() {
  const mobileMenuRef = useRef();
  const hubRef = useRef();
  const heroRef = useRef();
  const navRef = useRef();
  const film1Ref = useRef();
  const film2Ref = useRef();
  const film3Ref = useRef();
  const film1ContRef = useRef();
  const film2ContRef = useRef();
  const film3ContRef = useRef();
  const filmModal1Ref = useRef();
  const filmModal2Ref = useRef();
  const filmModal3Ref = useRef();
  const film1TextRef = useRef();
  const film2TextRef = useRef();
  const film3TextRef = useRef();
  const copiedRef = useRef();
  const poll1 = useRef();
  const poll2 = useRef();
  const poll3 = useRef();
  const pollImage1 = useRef();
  const pollImage2 = useRef();
  const pollImage3 = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const positionMobileMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  useLayoutEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        gsap.to(window, { scrollTo: element, delay: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (showCopied) {
      copiedRef.current.style.visibility = 'visible';
      setTimeout(() => {
        setShowCopied(false);
      }, 1000);
    } else {
      copiedRef.current.style.visibility = 'hidden';
    }
  }, [showCopied]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      menuOpen
        ? tl.to(mobileMenuRef.current, { x: 0, duration: 0.3 })
        : tl.to(mobileMenuRef.current, { x: '100%', duration: 0.3 });
    });
    return () => ctx.revert();
  }, [menuOpen]);

  return (
    <WidthContextProvider>
      <div className="app" ref={hubRef}>
        <MobileMenu
          positionMobileMenu={positionMobileMenu}
          mobileMenuRef={mobileMenuRef}
          menuOpen={menuOpen}
        />
        <Nav navRef={navRef} positionMobileMenu={positionMobileMenu} hubRef={hubRef} />
        <Button origin="share" copiedRef={copiedRef} />
        <Hero heroRef={heroRef} />
        <HeroCopy />
        <Poll index={0} container={poll1} imageRef={pollImage1} />
        <Infographic />
        <Poll index={1} container={poll2} imageRef={pollImage2} />
        <Film
          animateRef={film1TextRef}
          index={0}
          filmRef={film1Ref}
          filmModalRef={filmModal1Ref}
          filmContRef={film1ContRef}
          setShowCopied={setShowCopied}
          navRef={navRef}
        />
        <Quote index={0} />
        <Film
          animateRef={film2TextRef}
          index={1}
          filmRef={film2Ref}
          filmModalRef={filmModal2Ref}
          filmContRef={film2ContRef}
          setShowCopied={setShowCopied}
          navRef={navRef}
        />
        <Quote index={1} />
        <Film
          animateRef={film3TextRef}
          index={2}
          filmRef={film3Ref}
          filmModalRef={filmModal3Ref}
          filmContRef={film3ContRef}
          setShowCopied={setShowCopied}
          navRef={navRef}
        />
        <Quote index={2} />
        <Poll index={2} container={poll3} imageRef={pollImage3} />
        <Cta />
      </div>
    </WidthContextProvider>
  );
}

export default App;
