export const quoteData = [
  {
    copy:
      "People are surprised of my age, but there's so many people over 65 doing amazing things. I'm on the flip side of this journey, I want to leave a legacy.",
    name: 'Robin, 71'
  },
  {
    copy:
      "You need to have something in your life that makes you stay up late at night thinking about it and get up early in the morning to go do it. And that's what I’ve got with comedy.",
    name: 'Gid, 78'
  },
  {
    copy:
      'When I first retired, I thought, ‘this is the best thing since sliced bread.’ When I go to bed at night, I can hardly fall asleep thinking about what I want to do in the garden the next day.',
    name: 'Norma, 66'
  }
];
