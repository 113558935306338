import React, { useEffect, useState, createContext } from 'react';

const WidthContext = createContext({
  width: window.innerWidth,
  viewSize: null,
  touchDevice: false
});

function WidthContextProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [viewSize, setViewSize] = useState();
  const [touchDevice, setTouchDevice] = useState();

  useEffect(() => {
    const handleResize = () => {
      const current = window.innerWidth;
      setWidth(current);
      if (current > 767) {
        setViewSize('large');
      } else {
        setViewSize('small');
      }
    };
    const handleTouch = () => {
      setTouchDevice(true);
      window.removeEventListener('touchstart', handleTouch);
    };
    window.addEventListener('resize', handleResize, { passive: true });
    window.addEventListener('touchstart', handleTouch, { passive: true });
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('touchstart', handleTouch);
    };
  }, []);
  return (
    <WidthContext.Provider value={{ width, viewSize, touchDevice }}>
      {children}
    </WidthContext.Provider>
  );
}

export { WidthContext, WidthContextProvider };
