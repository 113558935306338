import React, { useEffect, useRef } from 'react';
import './quote.scss';
import { quoteData } from './quoteData';
import qMark from '../../assets/quotation_mark.svg';
function Quote({ index }) {
  const animateRef = useRef();
  useEffect(() => {
    const container = animateRef.current;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            animateRef.current.style.opacity = 1;
            animateRef.current.style.transform = 'translateY(0)';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(container);
    return () => {
      observer.unobserve(container);
    };
  }, []);
  return (
    <div className="quote">
      <div ref={animateRef} className=" quote-contents text--animate text--animate-transition">
        <img src={qMark} className="quote__qMark" alt="" />
        <p>{quoteData[index].copy}</p>

        <img src={qMark} className="quote__qMark quote__qMark--end" alt="" />
        <span className="quote__author">{quoteData[index].name}</span>
      </div>
    </div>
  );
}

export default Quote;
