import React, { Fragment } from 'react';
import './poll.scss';
import { useCountUp } from 'react-countup';

function Result({ i, result, answer, index, resultRef, numRef, showCounter }) {
  const SimpleHook = ({ index, number }) => {
    useCountUp({
      ref: `counter${index}`,
      end: number,
      start: 0,
      duration: 1.2,
      delay: 0.5
    });
    return <span id={`counter${index}`} />;
  };
  return (
    <Fragment>
      <div className="poll__result__graph">
        <div className="poll__result__graph__bar" />
        <div className="poll__result__graph__bar poll__result__graph__bar-fill" ref={resultRef} />
        <span className="poll__result__graph__num">
          {showCounter ? <SimpleHook index={`${index}-${i}`} number={result} /> : `0`}%
        </span>
      </div>
      <p className="poll__result__small">{answer}</p>
    </Fragment>
  );
}

export default Result;
