import React from 'react';
import './button.scss';
import { buttonData } from './buttonData';

function Button({ origin, click, index, copiedRef }) {
  return (
    <button
      ref={copiedRef && copiedRef}
      className={`button button-${origin}`}
      onClick={
        click
          ? () => {
              click(index);
            }
          : null
      }
    >
      {origin === 'cta' ? (
        <a
          href="https://www.humana.com/"
          target="_blank"
          rel="noreferrer"
          aria-label="Visit www.humana.com"
          className="button button__link button-cta__link"
        >
          {buttonData[origin]}
        </a>
      ) : (
        buttonData[origin]
      )}
    </button>
  );
}

export default Button;
