import { useEffect, useRef } from 'react';
import bgD from '../../assets/hero_bgD.mp4';
import bgM from '../../assets/hero_bgM.mp4';
import { gsap } from '../../gsap';
import './hero.scss';

const Hero = ({ heroRef }) => {
  const textRef = useRef();
  useEffect(() => {
    gsap.to(textRef.current, { opacity: 1, y: 0, duration: 1 });
  }, []);

  return (
    <section className="hero" ref={heroRef}>
      <video muted autoPlay loop playsInline className="videoloop videoloop-d">
        <source src={bgD} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <video muted autoPlay loop playsInline className="videoloop videoloop-m videoloop-m-hero">
        <source src={bgM} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <h1 className="text--animate" ref={textRef}>
        The Original Influencers
      </h1>
    </section>
  );
};

export default Hero;
