import React, { useEffect, useContext } from 'react';
import './mobileMenu.scss';
import Logo from '../logo/Logo';
import { navItems } from '../../navItems';
import exit from '../../assets/close.svg';
import { WidthContext } from '../../WidthContext';
import FilmIcon from '../filmIcon/FilmIcon';

function MobileMenu({ positionMobileMenu, mobileMenuRef, menuOpen }) {
  const { width } = useContext(WidthContext);
  useEffect(() => {
    if (width > 767 && menuOpen) {
      positionMobileMenu();
    }
  }, [menuOpen, width, positionMobileMenu]);
  return (
    <nav className="mobileMenu" ref={mobileMenuRef} aria-label="section navigation">
      <div className="mobileMenu__contents">
        <button
          className="mobileMenu__contents__close"
          aria-label="Close menu"
          onClick={positionMobileMenu}
        >
          <img
            src={exit}
            aria-hidden="true"
            focusable="false"
            className="mobileMenu__contents__close-img"
            alt=""
          />
        </button>
        <ul>
          {navItems.map(({ href, copy, copy2, film }, i) => {
            return (
              <li className="mobileMenu__contents__item" key={i}>
                <div className="mobileMenu__contents__item-container">
                  {/* <span className="mobileMenu__contents__item__episode">{film}</span> */}
                  {copy2 ? (
                    <>
                      <FilmIcon origin={'menu'} />
                      <span
                        className="mobileMenu__contents__item__link"
                        href={href}
                        onClick={positionMobileMenu}
                      >
                        {copy}
                      </span>
                      <span className="mobileMenu__contents__item__cs">Coming Soon</span>
                    </>
                  ) : (
                    <a
                      className="mobileMenu__contents__item__link"
                      href={href}
                      onClick={positionMobileMenu}
                    >
                      <FilmIcon origin={'menu'} />
                      {copy}
                    </a>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <a
          className="mobileMenu__contents__logo"
          href="https://www.humana.com/"
          target="_blank"
          rel="noreferrer"
          aria-label="Link to www.humana.com"
        >
          <Logo origin="menu" />
        </a>
      </div>
    </nav>
  );
}

export default MobileMenu;
