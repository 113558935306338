import videoloop1D from '../../assets/film1_bgD.mp4';
import videoloop1M from '../../assets/film1_bgM.mp4';
import videoloop2D from '../../assets/film2_bgD.mp4';
import videoloop2M from '../../assets/film2_bgM.mp4';
import videoloop3D from '../../assets/film3_bgD.mp4';
import videoloop3M from '../../assets/film3_bgM.mp4';

export const filmData = [
  {
    videoId: 'film1Player',
    url:
      'https://player.vimeo.com/video/859776582?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 'robin',
    name: 'Robin Borlandoe',
    copy:
      'When a lifeguard shortage threatened to close the neighborhood pool, 70-year-old Robin became a certified lifeguard to keep it open for the kids in her community.',
    loopD: videoloop1D,
    loopM: videoloop1M
  },
  {
    videoId: 'film2Player',
    url:
      'https://player.vimeo.com/video/859574827?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 'gid',
    name: 'Gid Pool',
    copy:
      'After retiring from real estate at 61 years old, Gid took a stand-up-comedy class at a local nightclub. He always loved to make people laugh but never imagined comedy would become his second act.',
    loopD: videoloop2D,
    loopM: videoloop2M
  },
  {
    videoId: 'film3Player',
    url:
      'https://player.vimeo.com/video/868450133?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479',
    id: 'norma',
    name: 'Norma Michael',
    copy:
      'Inspired to transform a once-empty lot into a thriving neighborhood garden, Norma, at the age of 66, now nourishes her community with the fruits and vegetables she grows.',
    loopD: videoloop3D,
    loopM: videoloop3M
  }
];
