import { useEffect, useRef } from 'react';
import './hero.scss';

const HeroCopy = () => {
  const animateRef = useRef();

  useEffect(() => {
    const container = animateRef.current;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            animateRef.current.style.opacity = 1;
            animateRef.current.style.transform = 'translateY(0)';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(container);
    return () => {
      observer.unobserve(container);
    };
  }, []);

  return (
    <section className="hero__copy ">
      <p className="text--animate text--animate-transition" ref={animateRef}>
        Turning 65 is often seen as a finish line when it’s just the opposite. Seniors are
        connecting, exploring, and engaging in adventures like never before. This series of short
        films debunks the old stereotypes of what it means to be over 65, by celebrating individuals
        who continue to make a difference. From community service to comedy, these seniors prove
        that dated myths about retirement age just aren’t the reality. Watch and share these
        inspiring stories, and tag #Humana to tell us how you’re breaking the 65+ stereotype!
      </p>
    </section>
  );
};

export default HeroCopy;
